










































import { StatisticsPeriodKey, ChartInfo } from '@/views/chat/statistics/logic/types'
import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics } from '@/includes/Api/Statistics.api'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'

import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

@Component({
  components: {
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  },
  data() {
    return {
      StatisticsPeriodKey
    }
  }
})
export default class EngagementRate extends UseFields{
  @Prop() rawData!: ChatStatistics

  @Prop() period!: { from: string, to: string }

  monthTable: any = null
  weekTable: any = null

  currentPeriod: StatisticsPeriodKey = StatisticsPeriodKey.Month

  engagementRate: { month: ChartInfo, week: ChartInfo } | null = null

  get engagementRateChartTitle() {
    if (this.currentPeriod === StatisticsPeriodKey.Month) {
      return this.$t(`user_involvement_chart_month_title`)
    }

    return this.$t(`user_involvement_chart_week_title`)
  }

  getOptions(period: Array<string>): ApexOptions {
    return {
      chart: {
        type: "area",
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales,
        animations: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      colors: [ '#5cadff' ],
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        min: 0,
        max: 100,
        labels: {
          formatter: (value: number) => {
            return `${ value }%`
          }
        }
      },
      tooltip: {
        y:{
          formatter(val: number, opts?: any): string {
            return `${ val.toFixed(2) }%`
          }
        }
      },
      xaxis: {
        type: 'datetime',
        categories: period,
      },
    }
  }

  get messageEngagementSorted() {
    return this.rawData.message_engagement
      .slice()
      .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
  }

  getMonthTable(): void {
    this.monthTable = this.messageEngagementSorted.map(item => {
      const obj: Record<string, string> = {}

      obj[this.$t('statistics_table_date_title').toString()] = item.date

      if (item.month_engagement_rate_by_users !== undefined) {
        obj[this.$t('user_involvement_chart_title').toString()] = `${ item.month_engagement_rate_by_users.toFixed(2) }%`
      } else {
        obj[this.$t('user_involvement_chart_title').toString()] = '-'
      }

      return obj
    })
  }

  getWeekTable(): void {
    this.weekTable = this.messageEngagementSorted.map(item => {
      const obj: Record<string, string> = {}

      obj[this.$t('statistics_table_date_title').toString()] = item.date

      if (item.week_engagement_rate_by_users !== undefined) {
        obj[this.$t('user_involvement_chart_title').toString()] = `${ item.week_engagement_rate_by_users.toFixed(2) }%`
      } else {
        obj[this.$t('user_involvement_chart_title').toString()] = '-'
      }

      return obj
    })
  }

  mounted(): void {
    const fromTo = fromToTicks(
      this.period.from,
      moment(this.period.to).add(-1, 'd').format('YYYY-MM-DD'),
    )

    this.engagementRate = {
      week: {
        series:[],
        options: this.getOptions(fromTo.timeTicks)
      },
      month: {
        series: [],
        options: this.getOptions(fromTo.timeTicks)
      }
    }

    seriesByMetricInList(
      this.$i18n,
      this.rawData.message_engagement,
      [ 'month_engagement_rate_by_users' ],
      fromTo,
      'date'
    )
      .calcSeries()
      .then((res) => {
        this.engagementRate!.month.series = res
      })

    seriesByMetricInList(
      this.$i18n,
      this.rawData.message_engagement,
      [ 'week_engagement_rate_by_users' ],
      fromTo,
      'date'
    )
      .calcSeries()
      .then((res) => {
        this.engagementRate!.week.series = res
      })

    this.getMonthTable()
    this.getWeekTable()
  }
}
