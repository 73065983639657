import { render, staticRenderFns } from "./SmallChartsStatWrapper.vue?vue&type=template&id=bead0c0c&scoped=true&"
import script from "./SmallChartsStatWrapper.vue?vue&type=script&lang=ts&"
export * from "./SmallChartsStatWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./SmallChartsStatWrapper.vue?vue&type=style&index=0&id=bead0c0c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bead0c0c",
  null
  
)

export default component.exports