


































































































































import { StatisticsPeriodKey } from '@/views/chat/statistics/logic/types'
import MsgActivityBars from "@/views/chat/statistics/components/mainStatistics/msgActivityBars.vue";
import UsersActivityBars from "@/views/chat/statistics/components/mainStatistics/usersActivityBars.vue";
import { ChatStatistics } from '@/includes/Api/Statistics.api';

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { sum } from 'lodash'

@Component({
  components: {
    MsgActivityBars,
    UsersActivityBars
  },
  data() {
    return {
      StatisticsPeriodKey
    }
  }
})
export default class SmallChartsStatWrapper extends Vue {
  @Prop() rawData!: ChatStatistics

  get avg_msg_count_day() {
    return (
      this.rawData
      && this.rawData.message_count_by_day
      && this.rawData.message_count_by_day.length
      && Math.floor(sum(this.rawData.message_count_by_day.map(item => item.message_count)) / this.rawData.message_count_by_day.length)
    ) || 0
  }

  get totalMessagesCountMonth() {
    return sum(this.rawData.message_count_by_day.map(item => item.message_count))
  }

  get avg_msg_count_hour() {
    return (
      this.rawData
      && this.rawData.message_count_by_hour
      && this.rawData.message_count_by_hour.length
      && Math.floor(sum(this.rawData.message_count_by_hour.map(msg => msg.message_count)) / this.rawData.message_count_by_hour.length)
    ) || 0
  }
}
