


























import { ChartInfo, StatisticsPeriodKey } from '@/views/chat/statistics/logic/types'
import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics } from '@/includes/Api/Statistics.api'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  },
  data() {
    return {
      StatisticsPeriodKey
    }
  }
})
export default class MsgUsersCount extends UseFields {
  @Prop() rawData!: ChatStatistics

  @Prop() period!: { from: string, to: string }

  dayTable: any = null
  // weekTable: any = null

  series:ApexChartSeries = []

  currentPeriod: StatisticsPeriodKey = StatisticsPeriodKey.Day

  msgUsersCount: { byDays: ChartInfo, week: ChartInfo } | null = null

  getDayTable(): void {
    const dayTable: Array<{[key: string]: string | number}> = []
    const sorted = this.rawData.message_count_by_day
      .slice()
      .sort((a, b) => moment(a.date, 'YYYY-MM-DD').valueOf() - moment(b.date, 'YYYY-MM-DD').valueOf())

    sorted.forEach((item, index) => {
      let obj: {[key: string]: string | number} = {}

      obj[this.$t('statistics_csv_date_field').toString()] = item.date
      obj[this.$t('statistics_users_count_title').toString()] = item.users_count
      obj[this.$t('users_statistics_count_active').toString()] = item.message_count

      // if (index === 0) {
      //   obj[this.$t('statistics_users_count_sma_title').toString()] = '-'
      //   obj[this.$t('statistics_msg_count_sma_title').toString()] = '-'
      // } else if (index === 1) {
      //   obj[this.$t('statistics_users_count_sma_title').toString()] = item.message_count
      //   obj[this.$t('statistics_msg_count_sma_title').toString()] = item.users_count
      // } else {
      //   const smaUser = (sorted[index - 1].users_count / 100 * 75) + item.users_count / 100 * 25
      //   const smaMsg = (sorted[index - 1].message_count / 100 * 75) + item.message_count / 100 * 25
      //
      //   obj[this.$t('statistics_users_count_sma_title').toString()] = Math.round(smaMsg)
      //   obj[this.$t('statistics_msg_count_sma_title').toString()] = Math.round(smaUser)
      // }

      dayTable.push(obj)
    })

    this.dayTable = dayTable
  }

  // getWeekTable(): void {
  //   const weekTable: Array<{[key: string]: number | string}> = []
  //   this.rawData.msg_count_week.forEach((item, index) => {
  //     let obj: {[key: string]: number | string} = {}
  //     obj[this.$t('users_statistics_count_active').toString()] = item.users_count
  //     obj[this.$t('statistics_users_count_title').toString()] = item.msg_count
  //     if (index === 0) {
  //       obj[this.$t('statistics_users_count_sma_title').toString()] = '-'
  //       obj[this.$t('statistics_msg_count_sma_title').toString()] = '-'
  //     } else if (index === 1) {
  //       obj[this.$t('statistics_users_count_sma_title').toString()] = item.msg_count
  //       obj[this.$t('statistics_msg_count_sma_title').toString()] = item.users_count
  //     } else {
  //       const smaUser = (this.rawData.msg_count_day[index - 1].users_count / 100 * 75) + item.users_count / 100 * 25
  //       const smaMsg = (this.rawData.msg_count_day[index - 1].msg_count / 100 * 75) + item.msg_count / 100 * 25
  //       obj[this.$t('statistics_users_count_sma_title').toString()] = Math.round(smaMsg)
  //       obj[this.$t('statistics_msg_count_sma_title').toString()] = Math.round(smaUser)
  //     }
  //     weekTable.push(obj)
  //   })
  //   this.weekTable = weekTable
  // }

  getOptions(period: string, labels: Array<string>): ApexOptions {
    return {
      chart: {
        type: "bar",
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales,
        animations: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
      },
      colors: [  'rgba(var(--a-success)', 'rgba(var(--a-primary)' ],
      dataLabels: {
        enabled: false
      },
      yaxis: {
        labels :{
          formatter:(val: number, opts?: any): string | string[] => val.toFixed(0)
        }
      },
      xaxis: {
        type: this.currentPeriod === StatisticsPeriodKey.Day ? "datetime" : 'category',
        categories: labels,
        tooltip:{
          enabled: false
        }
      },
    }
  }

  mounted() {
    const from = moment(this.period.from).format('YYYY-MM-DD')
    const to = moment(this.period.to).format('YYYY-MM-DD')

    const fromTo = fromToTicks(from, to, { format: 'YYYY-MM-DD' })

    const optionsTimeTicks = fromTo.timeTicks.map(t => moment(t).format('YYYY-MM-DD'))
    const seriesNames = [ { name: this.$t('statistics_users_count_title').toString() }, { name: this.$t('statistics_msg_count_title').toString() }, ]

    this.msgUsersCount = {
      byDays: {
        series: [],
        options: this.getOptions('days', optionsTimeTicks)
      },
      week: {
        series: [],
        options: this.getOptions('week', optionsTimeTicks)
      }
    }

    seriesByMetricInList(this.$i18n, this.rawData.message_count_by_day, [ 'users_count', 'message_count' ], fromTo, 'date')
      .calcSeries(seriesNames)
      .then(res => {
        this.msgUsersCount!.byDays.series = res
      })

    // seriesByMetricInList(this.$i18n, this.rawData.msg_count_week, [ 'users_count', 'msg_count' ], fromTo)
    //   .calcSeries(seriesNames)
    //   .then(res => {
    //     this.msgUsersCount!.week.series = res
    //   })

    this.getDayTable()
    // this.getWeekTable()
  }

  getAvgArr(arr1: Array<number | null>, arr2: Array<number | null>): Array<number | null> {
    arr1.forEach((item, index) => {
      if (index === 0) {
        arr2.push(null)
      } else if (index === 1) {
        arr2.push(item)
      } else {
        arr2.push(Math.round((arr2[index - 1]! / 100 * 75) + item! / 100 * 25))
      }
    })
    return arr2
  }
}
