import { render, staticRenderFns } from "./msgCountHeatmap.vue?vue&type=template&id=63c39963&scoped=true&"
import script from "./msgCountHeatmap.vue?vue&type=script&lang=ts&"
export * from "./msgCountHeatmap.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c39963",
  null
  
)

export default component.exports