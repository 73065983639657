





































import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Emit, Mixins, VModel } from 'vue-property-decorator'
import moment from "moment";

@Component({
  components: {
  }
})
export default class SetPeriod extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() period!: { from: string, to: string }

  @Emit('searchPeriod')
  searchPeriod() {
  }

  get isRightDate() {
    return (moment(this.period.from).isBefore(this.period.to, "day")) && (!moment(this.period.to).isAfter(moment(), "day"));
  }
}
