




























import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics } from "@/includes/Api/Statistics.api";
import heatMapColorRangeGenerator from '@/views/chat/statistics/logic/HeatMapColorRange'
import { ChartInfo } from '../../logic/types'

import { UseFields } from "piramis-base-components/src/components/Pi";
import HeatmapChart from "piramis-base-components/src/components/Charts/Heatmap/heatmap.vue";
import TableExportButtons from "piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue";
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import moment from "moment";
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import { Component, Prop } from "vue-property-decorator";
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue';

@Component({
  components: {
    HeatmapChart,
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  }
})
export default class UsersRetention extends UseFields {
  @Prop() rawData!: ChatStatistics['retention']

  @Prop() period!: { from: string, to: string }

  retentionTable: any = null

  usersRetentionChart: ChartInfo | null = null

  getRetentionTable(): void {
    const sortedRetention = this.rawData.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())

    this.retentionTable = sortedRetention.reduce((acc:Array<Record<string, string | number>>, current) => {
      acc.push({
        [this.$t('statistics_table_date_title').toString()]: current.date,
        [`1 ${ this.$t('day') }`]: current.days_with_message_count_1,
        [`2 ${ this.$t('day') }`]: current.days_with_message_count_2,
        [`3 ${ this.$t('day') }`]: current.days_with_message_count_3,
        [`4 ${ this.$t('day') }`]: current.days_with_message_count_4,
        [`5 ${ this.$t('day') }`]: current.days_with_message_count_5,
        [`6 ${ this.$t('day') }`]: current.days_with_message_count_6,
        [`7 ${ this.$t('day') }`]: current.days_with_message_count_7,
        [`8 ${ this.$t('day') }`]: current.days_with_message_count_8,
        [`9 ${ this.$t('day') }`]: current.days_with_message_count_9,
        [`10 ${ this.$t('day') }`]: current.days_with_message_count_10,
      })

      return acc
    }, [])
  }

  mounted(): void {
    const fromTo = fromToTicks(moment(this.period.from).format(),
                               moment(this.period.to).add(-1, 'd').format(),
                               { format: 'YYYY-MM-DD' })

    seriesByMetricInList(
      this.$i18n,
      this.rawData,
      [
        'days_with_message_count_1',
        'days_with_message_count_2',
        'days_with_message_count_3',
        'days_with_message_count_4',
        'days_with_message_count_5',
        'days_with_message_count_6',
        'days_with_message_count_7',
        'days_with_message_count_8',
        'days_with_message_count_9',
        'days_with_message_count_10',
      ],
      fromTo,
      'date'
    )
      .calcSeries()
      .then((res) => {
        this.usersRetentionChart = {
          series: res,
          options: this.getOptions(res, fromTo.timeTicks)
        }
      })

    this.getRetentionTable()
  }

  getOptions(series: ApexChartSeries, labels: Array<string>): ApexOptions {
    return {
      colors: [ 'rgba(var(--a-primary), 1)' ],
      chart: {
        type: 'heatmap',
        animations: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: heatMapColorRangeGenerator(series)
          }
        },
      },
      legend: {
        show: false
      },

      dataLabels: {
        enabled: false
      },
      xaxis: {
        labels: {
          datetimeUTC: false,
        },
        type: 'datetime',
        categories: labels,
        tickPlacement: "on",
      },
    }
  }

}
