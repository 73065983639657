









































import { ChartInfo, StatisticsPeriodKey } from '@/views/chat/statistics/logic/types'
import { WeekDaysLabels } from '../../logic/weekDays'
import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'
import { ChatStatistics } from '@/includes/Api/Statistics.api'
import heatMapColorRangeGenerator from '@/views/chat/statistics/logic/HeatMapColorRange'

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import TableExportButtons from 'piramis-base-components/src/components/TableExportButtons/TableExportButtons.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import HeatmapChart from 'piramis-base-components/src/components/Charts/Heatmap/heatmap.vue'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Component, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'
import { cloneDeep } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    HeatmapChart,
    TableExportButtons,
    VueApexCharts,
    HelpMessage
  },
  data() {
    return {
      StatisticsPeriodKey
    }
  }
})
export default class UsersActivityHeatmap extends UseFields {
  @Prop() rawData!: ChatStatistics

  @Prop() period!: { from: string, to: string }

  dayTable: any = null

  hourTable: any = null

  currentPeriod: StatisticsPeriodKey = StatisticsPeriodKey.Hour

  activityUsers: {hours: ChartInfo, days: ChartInfo} | null = null

  get isHour() {
    return this.currentPeriod === StatisticsPeriodKey.Hour
  }

  get chartTitle() {
    if (this.isHour) {
      return this.$t(`statistics_users_activity_chart_msg_activity_hour_title`).toString()
    }

    return this.$t(`statistics_users_activity_chart_msg_activity_day_title`).toString()
  }

  get datesByWeek(): Array<{ from: string, to: string }> {
    let dates: Array<{ from: string, to: string }> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))

    while (from.isBefore(to)) {
      dates.push({ from: from.format('YYYY-MM-DD'), to: from.add(6, 'days').format('YYYY-MM-DD') })
      from.add(1, 'days').format('YYYY-MM-DD')
    }

    return dates
  }

  getUsersFromWeeks(): ApexChartSeries {
    let weekDays: Array<Array<number>> = []

    this.rawData.message_count_by_day.forEach(msg => {
      const dateIndex = this.datesByWeek.findIndex((period) =>
        moment(msg.date).isSameOrAfter(period.from)
        && moment(msg.date).isSameOrBefore(period.to))

      const weekDay = moment(msg.date).day()
      if (weekDays[weekDay]) {
        weekDays[weekDay][dateIndex] += msg.message_count
      } else {
        weekDays[weekDay] = new Array(this.datesByWeek.length).fill(0)
        weekDays[weekDay][dateIndex] += msg.message_count
      }
    })

    let users: Array<{ name: string, data: Array<number> }> = []
    weekDays.push(weekDays.shift() as Array<number>)
    weekDays.forEach((week, index) => {
      users.push({
        name: this.$t(`week_day_picker_${ WeekDaysLabels[index].toLowerCase() }`).toString(),
        data: week
      })
    })
    return users.reverse()
  }

  getUsersFromDays(): ApexChartSeries {
    let times: Array<Array<number>> = []

    let from = moment(this.period.from)
    let to = moment(this.period.to).isSameOrBefore(moment()) ? moment(this.period.to) : moment(moment().format('YYYY-MM-DD'))
    let daysCount = to.diff(from, 'days')

    this.rawData.message_count_by_hour.forEach(msg => {
      const dateIndex = this.dayFromToTicks().timeTicks.indexOf(moment(msg.time).format('YYYY-MM-DD'))
      const time = Number(moment(msg.time).format('HH'))
      if (times[time]) {
        times[time][dateIndex] += msg.message_count
      } else {
        times[time] = new Array(daysCount).fill(0)
      }
    })

    let users: Array<{ name: string, data: Array<number> }> = []
    times.forEach((time, index) => {
      users.unshift({
        name: String(index).length > 1 ? `${ index }:00` : `0${ index }:00`,
        data: time
      })
    })

    return users
  }

  getOptions(catKey: StatisticsPeriodKey, labels: Array<string>): ApexOptions {
    return {
      chart: {
        type: 'heatmap',
        animations: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexLocales,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: heatMapColorRangeGenerator(catKey === StatisticsPeriodKey.Hour ? this.getUsersFromDays() : this.getUsersFromWeeks())
          }
        },
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: catKey === StatisticsPeriodKey.Hour ? 'datetime' : 'category',
        categories: labels,
        tickPlacement: "on",
      },
    }
  }

  dayFromToTicks() {
    const format = 'YYYY-MM-DD'

    return fromToTicks(moment(this.period.from).format(format), moment(this.period.to).add(-1, 'd').format(format))
  }

  mounted(): void {
    this.activityUsers = {
      hours: {
        series: [ ...this.getUsersFromDays() ],
        options: { ...this.getOptions(StatisticsPeriodKey.Hour, this.dayFromToTicks().timeTicks) }
      },
      days: {
        series: [ ...this.getUsersFromWeeks() ],
        options: { ...this.getOptions(StatisticsPeriodKey.Day, this.datesByWeek.map(item => this.labelsDatesRange(item.from, item.to, 'DD MMMM'))) }
      }
    }

    this.generateHoursTable()
    this.generateDaysTable()
  }

  generateHoursTable(): void {
    let times = fromToTicks(
      moment().startOf('d').format(),
      moment().endOf('d').format(),
      { format: 'HH:mm', amount: 1, unit: 'h' }
    ).mapTimeData;

    const hourTable: Array<any> = []
    this.rawData.message_count_by_hour.forEach(msg => {
      let findItem = hourTable.find(item => item[this.$t('statistics_table_date_title').toString()] === moment(msg.time).format('YYYY-MM-DD'))
      if (findItem) {
        findItem[moment(msg.time).format('HH:mm')] = msg.message_count
      } else {
        let copyTimes = cloneDeep(times)
        copyTimes[moment(msg.time).format('HH:mm')] = msg.message_count
        hourTable.push(Object.assign({ [this.$t('statistics_table_date_title').toString()]: moment(msg.time).format('YYYY-MM-DD') }, cloneDeep(copyTimes)))
      }
    })

    this.hourTable = hourTable
  }

  labelsDatesRange(from: string, to: string, format = 'YYYY-MM-DD') {
    return `${ moment(from).format(format) } - ${ moment(to).format(format) }`
  }

  generateDaysTable(): void {
    let week: { [key: string]: number } = {
      [this.$t('monday').toString()]: 0,
      [this.$t('tuesday').toString()]: 0,
      [this.$t('wednesday').toString()]: 0,
      [this.$t('thursday').toString()]: 0,
      [this.$t('friday').toString()]: 0,
      [this.$t('saturday').toString()]: 0,
      [this.$t('sunday').toString()]: 0
    }

    const times: Array<{[key: string]: string | number}> = this.datesByWeek.map(item => this.labelsDatesRange(item.from, item.to)).map(item => {
      return Object.assign({
        [this.$t('statistics_table_date_title').toString()]: item
      }, week)
    })

    const weekDays = Object.keys(week)

    this.rawData.message_count_by_day.forEach(msg => {
      const dateIndex = this.datesByWeek.findIndex((period) =>
        moment(msg.date).isSameOrAfter(period.from)
        && moment(msg.date).isSameOrBefore(period.to))

      if (this.datesByWeek[dateIndex]) {
        const weekDay = weekDays[moment(msg.date).weekday()]
        const period = `${ this.datesByWeek[dateIndex].from } - ${ this.datesByWeek[dateIndex].to }`
        const findWeek = times.find((item) => item[this.$t('statistics_table_date_title').toString()] === period)

        if (findWeek) {
          findWeek[weekDay] = msg.message_count
        } else {
          times.push(Object.assign({ [this.$t('statistics_table_date_title').toString()]: `${ this.datesByWeek[dateIndex].from } ${ this.datesByWeek[dateIndex].to }` }, cloneDeep(week)))
        }
      }

    })

    this.dayTable = times
  }
}
